// **  Initial State
const initialState = {
  session: { },
  response: null
}

const authReducer = (state = initialState, action) => {
  // console.log("action.type", action.type)
  switch (action.type) {
    // case 'LOGIN':
    //   return {
    //     ...state,
    //     [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
    //     [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
    //   }
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }         
    case 'AUTHENTICATE':
      return {
        ...state,
        session: { ...state.session, ...action.session, },
        response: action.response,
      }     
    case 'UPDATE_ACTIVE_ENVIRONMENT':
      return {
        ...state,
        session: { ...state.session, ...action.session, },
        response: action.response,
      }      
    case 'UPDATE_ACTIVE_LANGUAGES':
      return {
        ...state,
        session: { ...state.session, ...action.session, },
        response: action.response,
      }                  
    case 'LOGIN':
      return {
        ...state,
        session: { ...state.session, ...action.session, },
        response: action.response,
      }            
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, session: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
